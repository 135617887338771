import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Container, CircularProgress, makeStyles } from '@material-ui/core'
import useQueryString from 'hooks/useQueryString'

const RouteLoading: React.FC = () => {
  const classes = useStyles()
  const { token } = useQueryString()
  if (token) return null
  return (
    <Container maxWidth="lg">
      <Skeleton animation="wave" variant="text" height={90} />
      <div className={classes.routeLoadingMidWrapper}>
        <Skeleton animation="wave" variant="circle" width={115} height={100} />
        <div className={classes.routeLoadingMidSubWrapper}>
          <Skeleton animation="wave" variant="text" width="95%" height={50} />
          <Skeleton animation="wave" variant="text" width="70%" height={50} />
        </div>
      </div>
      <Skeleton animation="wave" variant="rect" width="100%" height={500} />
    </Container>
  )
}
export default RouteLoading

export const SiteLoading: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.siteLoadingRoot}>
      <CircularProgress />
    </div>
  )
}

export const TableLoading: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <Skeleton animation="wave" variant="text" height={90} />
      <Skeleton animation="wave" variant="rect" width="100%" height={200} />
    </Container>
  )
}

export const TableHeaderLoading: React.FC<{ height?: number }> = ({
  height,
}) => {
  return (
    <Container maxWidth="lg">
      <Skeleton
        animation="wave"
        variant="text"
        width="100%"
        height={height ? height : 90}
      />
    </Container>
  )
}

type TableContentLoadingProps = {
  maxWidth?: string
  height?: number
}

export const TableContentLoading: React.FC<TableContentLoadingProps> = ({
  maxWidth,
  height = 300,
}) => {
  return (
    <Container maxWidth="lg" style={{ maxWidth }}>
      <Skeleton animation="wave" variant="text" width="100%" height={height} />
    </Container>
  )
}

const useStyles = makeStyles({
  siteLoadingRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    marginTop: '20%',
  },
  routeLoadingMidWrapper: {
    display: 'flex',
    marginBottom: '20px',
  },
  routeLoadingMidSubWrapper: {
    width: '100%',
    marginLeft: 50,
  },
})
